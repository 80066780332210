import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEnkCurrentLayoutConfig, selectToolbarTheme } from 'app/store/enk/settingsSlice';
import { selectEnkNavbar } from 'app/store/enk/navbarSlice';
import {
  getSelectedSalon,
  selectUserPermissions,
  selectUser,
  setSelectedSalon,
} from 'app/store/userSlice';
import { findIndex } from 'lodash';
import CartMenu from 'app/theme-layouts/shared-components/CartMenu';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { SalonCartList } from 'src/app/auth/authPermissions';
import AdjustFontSize from '../../shared-components/AdjustFontSize';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import DarkLightToggle from '../../shared-components/DarkLightToggle';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';
import NavigationShortcuts from '../../shared-components/NavigationShortcuts';
import NavbarToggleButton from '../../shared-components/NavbarToggleButton';
import UserMenu from '../../shared-components/UserMenu';

function ToolbarLayout1(props) {
  const config = useSelector(selectEnkCurrentLayoutConfig);
  const navbar = useSelector(selectEnkNavbar);
  const selectedSalon = useSelector(getSelectedSalon);
  const [salonList, setSalonList] = useState([]);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const toolbarTheme = useSelector(selectToolbarTheme);
  const userPermissions = useSelector(selectUserPermissions);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      setSalonList(user?.data?.Salons || []);
      if (user?.data?.Salons?.length === 1 && !selectedSalon) {
        dispatch(setSelectedSalon(user.data.Salons[0]));
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, user, selectedSalon, navigate]);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="enk-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? toolbarTheme.palette.background.paper
              : toolbarTheme.palette.background.default,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64">
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden lgDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            <Hidden lgDown>
              <NavigationShortcuts />
            </Hidden>
          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">
            <LanguageSwitcher />

            {userPermissions?.includes(SalonCartList) && <CartMenu />}

            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden lgDown>
                {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
