import EnkSidePanel from '@enk/core/EnkSidePanel';
import { memo } from 'react';
import NavigationShortcuts from '../../shared-components/NavigationShortcuts';

function LeftSideLayout3() {
  return (
    <>
      <EnkSidePanel>
        <NavigationShortcuts className="py-16 px-8" variant="vertical" />
      </EnkSidePanel>
    </>
  );
}

export default memo(LeftSideLayout3);
