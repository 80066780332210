import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import { memo } from 'react';
import _ from '@lodash';
import GlobalStyles from '@mui/material/GlobalStyles';
import EnkNavHorizontalLayout1 from './horizontal/EnkNavHorizontalLayout1';
import EnkNavVerticalLayout1 from './vertical/EnkNavVerticalLayout1';
import EnkNavVerticalLayout2 from './vertical/EnkNavVerticalLayout2';
import EnkNavHorizontalCollapse from './horizontal/types/EnkNavHorizontalCollapse';
import EnkNavHorizontalGroup from './horizontal/types/EnkNavHorizontalGroup';
import EnkNavHorizontalItem from './horizontal/types/EnkNavHorizontalItem';
import EnkNavHorizontalLink from './horizontal/types/EnkNavHorizontalLink';
import EnkNavVerticalCollapse from './vertical/types/EnkNavVerticalCollapse';
import EnkNavVerticalGroup from './vertical/types/EnkNavVerticalGroup';
import EnkNavVerticalItem from './vertical/types/EnkNavVerticalItem';
import EnkNavVerticalLink from './vertical/types/EnkNavVerticalLink';
import { registerComponent } from './EnkNavItem';

const inputGlobalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      '.popper-navigation-list': {
        '& .enk-list-item': {
          padding: '8px 12px 8px 12px',
          height: 40,
          minHeight: 40,
          '& .enk-list-item-text': {
            padding: '0 0 0 8px',
          },
        },
        '&.dense': {
          '& .enk-list-item': {
            minHeight: 32,
            height: 32,
            '& .enk-list-item-text': {
              padding: '0 0 0 8px',
            },
          },
        },
      },
    })}
  />
);

/*
Register Enk Navigation Components
 */
registerComponent('vertical-group', EnkNavVerticalGroup);
registerComponent('vertical-collapse', EnkNavVerticalCollapse);
registerComponent('vertical-item', EnkNavVerticalItem);
registerComponent('vertical-link', EnkNavVerticalLink);
registerComponent('horizontal-group', EnkNavHorizontalGroup);
registerComponent('horizontal-collapse', EnkNavHorizontalCollapse);
registerComponent('horizontal-item', EnkNavHorizontalItem);
registerComponent('horizontal-link', EnkNavHorizontalLink);
registerComponent('vertical-divider', () => <Divider className="my-16" />);
registerComponent('horizontal-divider', () => <Divider className="my-16" />);

function EnkNavigation(props) {
  const options = _.pick(props, [
    'navigation',
    'layout',
    'active',
    'dense',
    'className',
    'onItemClick',
    'firstLevel',
    'selectedId',
  ]);
  if (props.navigation.length > 0) {
    return (
      <>
        {inputGlobalStyles}
        {props.layout === 'horizontal' && <EnkNavHorizontalLayout1 {...options} />}
        {props.layout === 'vertical' && <EnkNavVerticalLayout1 {...options} />}
        {props.layout === 'vertical-2' && <EnkNavVerticalLayout2 {...options} />}
      </>
    );
  }
  return null;
}

EnkNavigation.propTypes = {
  navigation: PropTypes.array.isRequired,
};

EnkNavigation.defaultProps = {
  layout: 'vertical',
};

export default memo(EnkNavigation);
