import i18next from 'i18next';
import { lazy } from 'react';

import en from './home/i18n/en';
import vn from './home/i18n/vn';

i18next.addResourceBundle('en', 'HomePage', en);
i18next.addResourceBundle('vn', 'HomePage', vn);

const Home = lazy(() => import('./home/Home'));
const SalonDetails = lazy(() => import('./salon-details/SalonDetails'));

const LandingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '',
      element: <Home />,
    },
    {
      path: ':queryString',
      element: <SalonDetails />,
    },
  ],
};

export default LandingConfig;
