import EnkNavigation from '@enk/core/EnkNavigation';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/enk/navigationSlice';
import useThemeMediaQuery from '@enk/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from 'app/store/enk/navbarSlice';

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  const dispatch = useDispatch();

  return useMemo(() => {
    function handleItemClick(item) {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }

    return (
      <EnkNavigation
        className={clsx('navigation', props.className)}
        navigation={navigation}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
        checkPermission={props.checkPermission}
      />
    );
  }, [
    dispatch,
    isMobile,
    navigation,
    props.active,
    props.className,
    props.dense,
    props.layout,
    props.checkPermission,
  ]);
}

Navigation.defaultProps = {
  layout: 'vertical',
  checkPermission: true,
};

export default memo(Navigation);
