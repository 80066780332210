/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['salonadmin'],
  staff: ['salonadmin', 'staff'],
  user: ['salonadmin', 'staff', 'user'],
  onlyGuest: [],
  restricted: ['restricted'],
};

export default authRoles;
