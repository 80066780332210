import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { useEffect, useLayoutEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EnkSvgIcon from '@enk/core/EnkSvgIcon';
import themesConfig from 'app/configs/themesConfig';
import { changeEnkTheme } from 'app/store/enk/settingsSlice';
import { useDispatch } from 'react-redux';

const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

function DarkLightToggle(props) {
  const mode = window.localStorage.getItem('theme_mode');
  const dispatch = useDispatch();
  const [themeMode, setThemeMode] = useState(mode || 'light');

  useEnhancedEffect(() => {});

  function toggleTheme() {
    if (themeMode === 'dark') {
      const theme = {
        main: themesConfig.default,
        navbar: themesConfig.defaultDark,
        toolbar: themesConfig.default,
        footer: themesConfig.default,
      };
      dispatch(changeEnkTheme(theme, 'light'));
      setThemeMode('light');
    } else {
      const theme = {
        main: themesConfig.defaultDark,
        navbar: themesConfig.defaultDark,
        toolbar: themesConfig.defaultDark,
        footer: themesConfig.defaultDark,
      };
      dispatch(changeEnkTheme(theme, 'dark'));
      setThemeMode('dark');
    }
  }

  return (
    <Tooltip title="Toggle Dark / Light Mode" placement="bottom">
      <IconButton onClick={toggleTheme} className={clsx('w-40 h-40', props.className)} size="large">
        {themeMode === 'dark' ? (
          <EnkSvgIcon>material-twotone:nightlight</EnkSvgIcon>
        ) : (
          <EnkSvgIcon>material-twotone:light_mode</EnkSvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export default DarkLightToggle;
