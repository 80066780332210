import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/enk/messageSlice';
import * as yup from 'yup';
import _ from '@lodash';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import jwtService from '../../auth/services/jwtService/jwtService';

const Background = styled('div')(({ theme, value }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundImage: `url(assets/images/reset-password-bg.jpg)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const defaultValues = {
  password: '',
  passwordConfirm: '',
};

function ResetPasswordPage() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [checkingToken, setCheckingToken] = useState(true);
  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [emplpyeeID, setEmployeeID] = useState(null);
  const [successChangePassword, setSuccessChangePassword] = useState(false);

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;

  useEffect(() => {
    if (token) {
      jwtService
        .checkToken(token)
        .then((data) => {
          if (data.success) {
            setAuthToken(data.accessToken);
            setEmployeeID(data.emplpyee_id);
          } else {
            setErrorMessage(data.message);
            setAuthToken(null);
          }
          console.log(data);
        })
        .catch((error) => {
          setErrorMessage('Error checking token');
          console.log(error);
        })
        .finally(() => {
          setCheckingToken(false);
        });
    }
  }, [token]);

  function onSubmit({ password }) {
    setLoading(true);
    jwtService
      .resetNewPassword({
        password,
        accessToken: authToken,
      })
      .then((data) => {
        if (data.success) {
          dispatch(
            showMessage({
              message: 'Password reset link sent successfully',
              variant: 'success',
              autoHideDuration: 10000,
            })
          );
          setSuccessChangePassword(true);
          setTimeout(() => {
            window.document.location.href = '/sign-in';
          }, 5000);
        } else {
          setErrorMessage(data.message);
          setAuthToken(null);
        }
        console.log(data);
      })
      .catch((_errors) => {
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img className="w-48" src="/assets/images/logo/logo.svg" alt="logo" />

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Reset Password
          </Typography>

          {checkingToken ? (
            <div className="flex items-baseline mt-24 font-700 text-xl mb-24">
              <CircularProgress color="secondary" size="16px" thickness={2} className="mr-10" />
              <Typography className="text-xl">Checking your data ..</Typography>
            </div>
          ) : (
            <div>
              {authToken ? (
                <form
                  name="registerForm"
                  noValidate
                  className="flex flex-col justify-center w-full mt-32"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="flex items-baseline mt-4 font-medium text-xl mb-24">
                    <Typography>Please enter your new password</Typography>
                  </div>

                  {successChangePassword && (
                    <div className="flex items-baseline mt-24 font-700 mb-24">
                      <Alert severity="success" className="p-10">
                        <AlertTitle>Success</AlertTitle>
                        Password has been changed successfully
                      </Alert>
                    </div>
                  )}

                  {!successChangePassword && (
                    <>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            label="New Password"
                            type="password"
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />

                      <Controller
                        name="passwordConfirm"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            label="New Password (Confirm)"
                            type="password"
                            error={!!errors.passwordConfirm}
                            helperText={errors?.passwordConfirm?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />

                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        className="w-full mt-24"
                        aria-label="Register"
                        disabled={_.isEmpty(dirtyFields) || !isValid}
                        type="submit"
                        size="large"
                        loading={loading}
                      >
                        Reset
                      </LoadingButton>
                    </>
                  )}
                </form>
              ) : (
                <div className="flex items-baseline mt-24 font-700 mb-24">
                  <Alert severity="error" className="p-10">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage || ' Password reset token is invalid.'}
                  </Alert>
                </div>
              )}
            </div>
          )}

          <div className="flex items-center mt-32">
            <Link className="ml-4" to="/sign-in">
              Return to the SignIn Page
            </Link>
          </div>
        </div>
      </Paper>

      <Box
        className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
        sx={{
          backgroundColor: 'primary.main',
        }}
      >
        <Background className="opacity-30" />
        <div className="z-10 relative w-full max-w-2xl">
          <div className="text-7xl font-bold leading-none text-gray-100">
            <div>UbookToday </div>
            <small>Appoitments Management</small>
          </div>
          <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">
            This area specified for Customers only.
          </div>
        </div>
      </Box>
    </div>
  );
}

export default ResetPasswordPage;
