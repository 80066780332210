const jwtServiceConfig = {
  signIn: `${process.env.REACT_APP_API_URL}/auth/sign-in`,
  signUp: `${process.env.REACT_APP_API_URL}/auth/sign-up`,
  accessToken: `${process.env.REACT_APP_API_URL}/auth/access-token`,
  updateUser: `${process.env.REACT_APP_API_URL}/auth/user/update`,
  profile: `${process.env.REACT_APP_API_URL}/customer/profile`,
  resetPassword: `${process.env.REACT_APP_API_URL}/auth/customers/reset`,
  checkresetToken: `${process.env.REACT_APP_API_URL}/auth/customers/check`,
  changePassword: `${process.env.REACT_APP_API_URL}/auth/customers/update`,
};

export default jwtServiceConfig;
