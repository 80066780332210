import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import CountryCodeSelector from 'app/shared-components/CountryCodeSelector';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import _ from 'lodash';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  first_name: yup.string().required('You must enter display name'),
  last_name: yup.string(),
  email: yup.string().email('You must enter a valid email'),
  mobile: yup.string(),
  country_code: yup.string().default('us'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirm_password: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
  acceptTermsConditions: yup.boolean().oneOf([true], 'The terms and conditions must be accepted.'),
});

const defaultValues = {
  first_name: '',
  last_name: '',
  mobile: '',
  country_code: 'us',
  email: '',
  password: '',
  acceptTermsConditions: false,
};

function SpaRegistration() {
  const dispatch = useDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const { control, formState, handleSubmit, reset, setError, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  function onSubmit(data) {}

  return (
    <div>
      <Box
        component="section"
        sx={{
          backgroundImage: 'url(assets/images/pages/home/spa5.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Container className="py-20">
          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <Typography className="text-white font-bold text-18 mb-10">
                Are You a Spa/Salon?
              </Typography>
              <Typography className="text-white mb-14">
                Get more customers, increase exposure, and fill those empty appointments. Join
                thousands of local businesses and create a free profile today.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className="flex justify-center">
              <img alt="Promotions" src="/assets/images/pages/home/groups.png" />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="flex justify-center py-32">
        <Paper className="w-full max-w-[700px] p-10 md:p-20">
          <Typography className="font-semibold text-24 text-center mb-20">
            SPA REGISTRATION
          </Typography>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="business_name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Bussiness Name"
                    placeholder="Enter business name here"
                    onChange={(ev) => field.onChange(ev.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="first_name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="First Name"
                    placeholder="Enter first name here"
                    onChange={(ev) => field.onChange(ev.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="last_name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Last Name"
                    placeholder="Enter last name here"
                    onChange={(ev) => field.onChange(ev.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Email"
                    placeholder="Enter email here"
                    onChange={(ev) => field.onChange(ev.target.value)}
                    helperText="(a verification code will be sent to this email)"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Password"
                    placeholder="Enter your password"
                    onChange={(ev) => field.onChange(ev.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="confirm_password"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Confirm Password"
                    placeholder="Enter your confirm password"
                    onChange={(ev) => field.onChange(ev.target.value)}
                    type={showConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="mobile"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Contact Number"
                    placeholder="Enter Your contact number"
                    variant="outlined"
                    fullWidth
                    error={!!formState.errors.mobile}
                    helperText={
                      formState?.errors?.mobile?.message ||
                      '(a verification code will be sent to this number)'
                    }
                    InputProps={{
                      startAdornment: (
                        <Controller
                          control={control}
                          name="country_code"
                          render={({ field: _field }) => (
                            <InputAdornment position="start">
                              <CountryCodeSelector
                                {..._field}
                                value={_field.value || 'us'}
                                size="small"
                                sx={{ fieldset: { border: 'none' } }}
                                hideLabel
                              />
                            </InputAdornment>
                          )}
                        />
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="addresses[0].address_line_1"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    label="Address"
                    id="address_line_1"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="addresses[0].country"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    label="Country"
                    id="country"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="addresses[0].state"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    label="State"
                    id="state"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="addresses[0].city"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    label="City"
                    id="city"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="addresses[0].zipcode"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    label="ZIP Code"
                    id="pincode"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="website"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    label="Website"
                    placeholder="Enter your website"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="acceptTermsConditions"
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(ev, ch) => field.onChange(ch)}
                      />
                    }
                    label={
                      <Typography>
                        By creating an account, you agree to our{' '}
                        <Link to="/terms">Terms & Conditions</Link>
                      </Typography>
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                variant="contained"
                color="secondary"
                className="rounded-4"
                loading={submitLoading}
                onClick={handleSubmit(onSubmit)}
                disabled={_.isEmpty(formState.dirtyFields) || !formState.isValid}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}

export default SpaRegistration;
