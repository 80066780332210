import BrowserRouter from '@enk/core/BrowserRouter';
import EnkLayout from '@enk/core/EnkLayout';
import EnkTheme from '@enk/core/EnkTheme';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import { selectUser } from 'app/store/userSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import { selectMainTheme } from 'app/store/enk/settingsSlice';
import EnkAuthorization from '@enk/core/EnkAuthorization';
import i18next from 'i18next';
import withAppProviders from './withAppProviders';
import { AuthProvider } from './auth/AuthContext';

import en from './main/i18n/en';
import vn from './main/i18n/vn';

// import axios from 'axios';
/**
 * Axios HTTP Request defaults
 */
// axios.defaults.baseURL = "";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

i18next.addResourceBundle('en', 'global', en);
i18next.addResourceBundle('vn', 'global', vn);

function App() {
  const user = useSelector(selectUser);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <EnkTheme theme={mainTheme} direction={langDirection}>
        <AuthProvider roleNames={user?.role}>
          <BrowserRouter>
            <EnkAuthorization {...user}>
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                }}
              >
                <EnkLayout layouts={themeLayouts} />
              </SnackbarProvider>
            </EnkAuthorization>
          </BrowserRouter>
        </AuthProvider>
      </EnkTheme>
    </CacheProvider>
  );
}

export default withAppProviders(App)();
