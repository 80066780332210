import EnkSvgIcon from '@enk/core/EnkSvgIcon';
import { Badge, CircularProgress, IconButton } from '@mui/material';
import {
  getCartList,
  selectCart,
  selectIsFetchingCart,
  setIsFetchingCart,
} from 'app/store/cartSlice';
import { showMessage } from 'app/store/enk/messageSlice';
import { getSelectedSalon } from 'app/store/userSlice';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function CartMenu() {
  const navigate = useNavigate();
  const selectedSalon = useSelector(getSelectedSalon);
  const productsInCart = useSelector(selectCart);
  const isFetchingCart = useSelector(selectIsFetchingCart);
  const dispatch = useDispatch();
  function onCartIconClick() {
    navigate('/cart');
  }
  useEffect(() => {
    if (selectedSalon && selectedSalon.id !== -1) {
      dispatch(setIsFetchingCart(true));
      dispatch(getCartList({ salon_id: selectedSalon.id })).then(({ error }) => {
        dispatch(setIsFetchingCart(false));
        if (error) {
          dispatch(
            showMessage({
              message: error.message || 'Error fetching cart',
              variant: 'error',
            })
          );
        }
      });
    }
  }, [dispatch, selectedSalon]);
  function getTotalQuantity() {
    return productsInCart.length;
  }
  return (
    <Badge
      badgeContent={
        isFetchingCart ? <CircularProgress size={10} color="text" /> : getTotalQuantity()
      }
      overlap="circular"
      color="secondary"
    >
      <IconButton onClick={onCartIconClick} className={clsx('w-40 h-40')} size="large">
        <EnkSvgIcon>heroicons-outline:shopping-cart</EnkSvgIcon>
      </IconButton>
    </Badge>
  );
}

export default CartMenu;
