export const SalonEmployeeCreate = 'salon::employee::create';
export const SalonEmployeeDelete = 'salon::employee::delete';
export const SalonEmployeeList = 'salon::employee::list';
export const SalonEmployeeUpdate = 'salon::employee::update';
export const SalonUpdate = 'salon::update';
export const SalonServicecategoryCreate = 'salon::servicecategory::create';
export const SalonServicecategoryUpdate = 'salon::servicecategory::update';
export const SalonServicecategoryDelete = 'salon::servicecategory::delete';
export const SalonServicecategoryList = 'salon::servicecategory::list';
export const SalonServicegroupCreate = 'salon::servicegroup::create';
export const SalonServicegroupUpdate = 'salon::servicegroup::update';
export const SalonServicegroupList = 'salon::servicegroup::list';
export const SalonServicegroupDelete = 'salon::servicegroup::delete';
export const SalonServiceCreate = 'salon::service::create';
export const SalonServiceUpdate = 'salon::service::update';
export const SalonServiceList = 'salon::service::list';
export const SalonServiceDelete = 'salon::service::delete';
export const SalonSettingList = 'salon::setting::list';
export const SalonAttendanceCreate = 'salon::attendance::create';
export const SalonAttendanceUpdate = 'salon::attendance::update';
export const SalonAttendanceDelete = 'salon::attendance::delete';
export const SalonAttendanceList = 'salon::attendance::list';
export const SalonReviewList = 'salon::review::list';
export const SalonReviewCreate = 'salon::review::create';
export const SalonReviewUpdate = 'salon::review::update';
export const SalonReviewDelete = 'salon::review::delete';
export const SalonRoomCreate = 'salon::room::create';
export const SalonRoomUpdate = 'salon::room::update';
export const SalonRoomDelete = 'salon::room::delete';
export const SalonRoomList = 'salon::room::list';
export const SalonCustomerList = 'salon::customer::list';
export const SalonProductList = 'salon::product::list';
export const SalonProductCreate = 'salon::product::create';
export const SalonProductUpdate = 'salon::product::update';
export const SalonProductDelete = 'salon::product::delete';
export const SalonCartDelete = 'salon::cart::delete';
export const SalonCartList = 'salon::cart::list';
export const SalonCartUpdate = 'salon::cart::update';
export const SalonCartCheckout = 'salon::cart::checkout';
export const SalonOrderList = 'salon::order::list';
export const SalonOrderUpdate = 'salon::order::update';
export const SalonProductcategoryList = 'salon::productcategory::list';
export const SalonManufacturerList = 'salon::manufacturer::list';
export const SalonCustomerCreate = 'salon::customer::create';
export const SalonCustomerDelete = 'salon::customer::delete';
export const SalonCustomerUpdate = 'salon::customer::update';
export const SalonGiftCardList = 'salon::gift-card::list';
export const SalonGiftCardUpdate = 'salon::gift-card::update';
export const SalonGiftCardDelete = 'salon::gift-card::delete';
export const SalonMembershipCreate = 'salon::membership::create';
export const SalonMembershipUpdate = 'salon::membership::update';
export const SalonMembershipList = 'salon::membership::list';
export const SalonMembershipDelete = 'salon::membership::delete';
export const SalonBookingList = 'salon::booking::list';
export const SalonReportsList = 'salon::reports::list';
export const SalonPayrollGroupCreate = 'salon::payroll-group::create';
export const SalonPayrollGroupUpdate = 'salon::payroll-group::update';
export const SalonPayrollGroupDelete = 'salon::payroll-group::delete';
export const SalonPayrollReportsCreate = 'salon::payroll-reports::create';
export const SalonPayrollReportsUpdate = 'salon::payroll-reports::update';
export const SalonPayrollReportsDelete = 'salon::payroll-reports::delete';
export const SalonPayrollReportsList = 'salon::payroll-reports::list';
export const SalonEmployeeRotationSignin = 'salon::employee::rotation-signin';
export const SalonEmployeeRotationList = 'salon::employee::rotation-list';
export const SalonEmployeeRotationUpdate = 'salon::employee::rotation-update';
export const SalonTicketList = 'salon::ticket::list';
export const SalonTicketUpdate = 'salon::ticket::update';
export const SalonTicketDelete = 'salon::ticket::delete';
export const SalonCalendarFullList = 'salon::calendar::full_list';
export const SalonCalendarOwnList = 'salon::calendar::own_list';
