import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SignUpForm from './SignUpForm';

const Background = styled('div')(({ theme, value }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundImage: `url(assets/images/spa-stones-flowers-fire.jpg)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

function SignUpPage() {
  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <SignUpForm />
      </Paper>

      <Box
        className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
        sx={{
          backgroundColor: 'primary.main',
        }}
      >
        <Background className="opacity-30" />
        <div className="z-10 relative w-full max-w-2xl">
          <div className="text-7xl font-bold leading-none text-gray-100">
            <div>Welcome to UbookToday</div>
          </div>
          <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">
            Discover the best salons and beauty services near you. Book appointments with ease and
            enjoy top-notch treatments from our curated list of trusted professionals. Sign in to
            your account to manage bookings, view your favorite salons, and take advantage of
            exclusive offers. If you're new here, join our community today for a seamless beauty and
            wellness experience. <br />
            We are here to help you look and feel your best!
            <br />
            Login or Sign Up to get started.
          </div>
        </div>
      </Box>
    </div>
  );
}

export default SignUpPage;
