import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import settingsConfig from 'app/configs/settingsConfig';

export const getPresignedUrlsFromFileUpload = createAsyncThunk(
  'storageModule/getPresignedUrlsFromFileUpload',
  async (payload) => {
    const response = await axios.post(
      `${settingsConfig.apiURL}/customer/${payload.customer_id}/upload`,
      payload
    );
    if (!response.data.success) {
      throw new Error('Failed to upload files');
    }
    return { items: response.data.items, errors: response.data.errors };
  }
);
export const uploadFiles = createAsyncThunk(
  'storageModule/uploadFiles',
  async ({ files = [], category = 'profile', customerId }, { dispatch }) => {
    const formData = new FormData();

    // Append files to formData, associating each with its category
    const ff = [];
    files.forEach((file) => {
      if (file && file.name) {
        console.log('file', file);
        formData.append('files', file, file.name);
        ff.push(file);
      }
    });

    try {
      if (ff.length > 0) {
        const response = await axios.post(
          `${settingsConfig.apiURL}/customer/${customerId}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (!response.data.success) {
          throw new Error('Failed to upload files');
        }

        return response.data.items;
      }

      return files;
    } catch (error) {
      throw new Error(error.message || 'Error uploading files');
    }
  }
);

const initialState = {};

const storageSlice = createSlice({
  name: 'storageModule',
  initialState,
});

export default storageSlice.reducer;
