import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .badge': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo(props) {
  return (
    <Root className="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
      {props.mode === 'light' ? (
        <img src="assets/images/logo/logo-text.svg" alt="logo" width={120} />
      ) : (
        <img src="assets/images/logo/logo-text-on-dark.svg" alt="logo" width={120} />
      )}
    </Root>
  );
}

export default Logo;
