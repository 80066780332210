import i18next from 'i18next';
import en from './navigation-i18n/en';
import vn from './navigation-i18n/vn';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('vn', 'navigation', vn);

const navigationConfig = [
  {
    id: 'profile',
    title: 'Profile',
    translate: 'PROFILE',
    type: 'item',
    icon: 'heroicons-solid:user',
    url: '/profile',
  },
];

export default navigationConfig;
