import i18next from 'i18next';
import { lazy } from 'react';

import en from './i18n/en';
import vn from './i18n/vn';
import serviceHistoryEn from './service-history/i18n/en';
import serviceHistoryVn from './service-history/i18n/vn';
import rewardEn from './reward/i18n/en';
import rewardVn from './reward/i18n/vn';
import membershipEn from './membership/i18n/en';
import membershipVn from './membership/i18n/vn';
import referralEn from './referral/i18n/en';
import referralVn from './referral/i18n/vn';

i18next.addResourceBundle('en', 'ProfilePage', en);
i18next.addResourceBundle('vn', 'ProfilePage', vn);
i18next.addResourceBundle('en', 'ServiceHistoryPage', serviceHistoryEn);
i18next.addResourceBundle('vn', 'ServiceHistoryPage', serviceHistoryVn);
i18next.addResourceBundle('en', 'RewardPage', rewardEn);
i18next.addResourceBundle('vn', 'RewardPage', rewardVn);
i18next.addResourceBundle('en', 'MembershipPage', membershipEn);
i18next.addResourceBundle('vn', 'MembershipPage', membershipVn);
i18next.addResourceBundle('en', 'ReferralPage', referralEn);
i18next.addResourceBundle('vn', 'ReferralPage', referralVn);

const ProfilePage = lazy(() => import('./ProfilePage'));
const ServiceHistoryList = lazy(() => import('./service-history/ServiceHistoryList'));
const RewardList = lazy(() => import('./reward/RewardList'));
const Referral = lazy(() => import('./referral/Referral'));
const MembershipList = lazy(() => import('./membership/MembershipList'));

const ProfileConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'profile',
      element: <ProfilePage />,
      children: [
        {
          path: 'service-history',
          element: <ServiceHistoryList />,
        },
        {
          path: 'reward',
          element: <RewardList />,
        },
        {
          path: 'referral',
          element: <Referral />,
        },
        {
          path: 'membership',
          element: <MembershipList />,
        },
      ],
    },
  ],
};

export default ProfileConfig;
