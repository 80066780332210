const locale = {
  TITLE: 'Profile',
  UNDER_TITLE: 'View & Manage Profile data',
  ACTION_CANCEL: 'Cancel',
  ACTION_SAVE: 'Save',
  ACTION_DELETE: 'Delete',
  ACTION_ADD: 'Add',
  ACTION_EDIT: 'Edit',
};

export default locale;
