import _ from '@lodash';
import { forwardRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import EnkUtils from '@enk/utils/EnkUtils';
import { FormControl, InputLabel, Select } from '@mui/material';

const CountryCodeSelector = forwardRef(
  ({ value, onChange, defaultValue, label, hideLabel, ...props }, ref) => {
    const countries = EnkUtils.getCountryListWithCallingCode();
    function renderValue(iso) {
      const country = _.find(countries, { iso });
      if (country) return `${country.code} (${country.iso.toUpperCase()})`;
      return '';
    }
    return (
      <FormControl ref={ref}>
        {!hideLabel && (
          <InputLabel id="country-code-sel-label">{label || 'Country Name (code)'}</InputLabel>
        )}
        <Select
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
          labelId="country-code-sel-label"
          label={!hideLabel ? label || 'Country Name (code)' : ''}
          variant="outlined"
          renderValue={renderValue}
          {...props}
          sx={{
            '&.MuiInputBase-root': {
              paddingRight: '4rem !important',
            },
            '.MuiSelect-select': {
              padding: '0 !important',
              minHeight: 'unset',
              lineHeight: 1,
            },
            ...props.sx,
          }}
        >
          {countries.map((item) => (
            <MenuItem key={item.iso} value={item.iso}>
              <span className="font-medium">{item.code}</span>
              <span className="ml-8 font-medium">({item.iso.toUpperCase()})</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export default CountryCodeSelector;
