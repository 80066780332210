import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import history from '@history';
import { setInitialSettings } from 'app/store/enk/settingsSlice';
import { showMessage } from 'app/store/enk/messageSlice';
import settingsConfig from 'app/configs/settingsConfig';
import axios from 'axios';
import _ from 'lodash';
import jwtService from '../auth/services/jwtService';

export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
  /*
    You can redirect the logged-in user to a specific route depending on his role
    */
  if (user.loginRedirectUrl) {
    settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example '/apps/academy'
  }

  return user;
});

export const updateUserSettings = createAsyncThunk(
  'user/updateSettings',
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const updateUserShortcuts = createAsyncThunk(
  'user/updateShortucts',
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const logoutUser = () => async (dispatch, getState) => {
  history.push({
    // pathname: '/sign-in',
    pathname: '/',
  });

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  jwtService
    .updateUserData(user)
    .then(() => {
      dispatch(showMessage({ message: 'Profile details saved' }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

export const updateUserProfileInfo = createAsyncThunk(
  'user/updateUserProfileInfo',
  async (options, { dispatch }) => {
    const response = await axios.post(`${settingsConfig.apiURL}/customer/${options.id}`, options);
    if (!response.data.success || !response.data.item) {
      throw new Error('Failed to update user profile details');
    }
    dispatch(setUser(jwtService.transformUserProfileData(response.data.item)));
    return response.data;
  }
);

export const resetUserAccountInfo = createAsyncThunk(
  'user/resetUserAccountInfo',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/customer/reset-account`, options);
    if (!response.data.success) {
      throw new Error('Failed to reset account');
    }
    return response.data;
  }
);

export const registerUser = createAsyncThunk('user/registerUser', async (options) => {
  const response = await axios.post(`${settingsConfig.apiURL}/customer/register`, options);
  return response?.data || { success: false };
});

export const verifyUser = createAsyncThunk('user/verifyUser', async (options) => {
  const response = await axios.post(`${settingsConfig.apiURL}/customer/verify`, options);
  if (!response.data.success) {
    throw new Error('Failed to verify account');
  }
  return response.data;
});

export const validateUser = createAsyncThunk('user/validateUser', async (options) => {
  const response = await axios.post(`${settingsConfig.apiURL}/customer/validate`, options);
  if (!response.data.success) {
    throw new Error('Failed to sign in. Please try again later.');
  }
  return response.data;
});

export const resetUserPassword = createAsyncThunk('user/resetUserPassword', async (options) => {
  const response = await axios.post(
    `${settingsConfig.apiURL}/customer/${options.id}/reset-password`,
    options
  );
  if (!response.data.success) {
    throw new Error('Failed to change profile password');
  }
  return response.data;
});

export const forgotPasswordInit = createAsyncThunk('user/forgotPasswordInit', async (options) => {
  const response = await axios.post(
    `${settingsConfig.apiURL}/customer/forgot-password/init`,
    options
  );
  if (!response.data.success) {
    throw new Error(response.data.message || 'Failed to reset password');
  }
  return response.data;
});

export const forgotPasswordComplete = createAsyncThunk(
  'user/forgotPasswordComplete',
  async (options) => {
    const response = await axios.post(
      `${settingsConfig.apiURL}/customer/forgot-password/complete`,
      options
    );
    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to reset password');
    }
    return response.data;
  }
);

export const setSelectedSalon = createAsyncThunk('user/setSelectedSalon', async (salon) => salon);

const initialState = {
  data: {
    displayName: 'Harshit Laddha',
    photoURL: 'assets/images/avatars/avatar.jpg',
    email: 'harshitladdha93@gmail.com',
    shortcuts: ['apps.dashboard', 'apps.spas'],
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    userLoggedOut: (state, action) => initialState,
    setLoadingUserPermissions: {
      reducer: (state, action) => {
        state.loadingUserPermissions = action.payload;
      },
      prepare: (payload) => ({ payload }),
    },
  },

  extraReducers: (builder) => {
    builder.addCase(updateUserSettings.fulfilled, (state, action) => action.payload);
    builder.addCase(updateUserShortcuts.fulfilled, (state, action) => action.payload);
    builder.addCase(setUser.fulfilled, (state, action) => action.payload);
    builder.addCase(resetUserAccountInfo.fulfilled, (state, action) => action.payload);
    builder.addCase(registerUser.fulfilled, (state, action) => action.payload);
    builder.addCase(verifyUser.fulfilled, (state, action) => action.payload);
    builder.addCase(validateUser.fulfilled, (state, action) => action.payload);
    builder.addCase(forgotPasswordInit.fulfilled, (state, action) => action.payload);
    builder.addCase(forgotPasswordComplete.fulfilled, (state, action) => action.payload);

    builder.addCase(setSelectedSalon.fulfilled, (state, action) => {
      state.selectedSalon = action.payload;
    });
  },
});

export const { userLoggedOut, setLoadingUserPermissions } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const getSelectedSalon = ({ user }) => user.selectedSalon;

export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export const selectLoadingUserPermissions = ({ user }) => user.loadingUserPermissions;

export const selectUserPermissions = ({ user }) => user.permission || [];

export default userSlice.reducer;
