const locale = {
  TITLE: 'Thẻ thành viên',
  UNDER_TITLE: 'Xem và Quản lý dữ liệu Thẻ thành viên',
  ACTION_CANCEL: 'Hủy',
  ACTION_SAVE: 'Lưu',
  ACTION_DELETE: 'Xóa',
  ACTION_ADD: 'Thêm',
  ACTION_EDIT: 'Chỉnh sửa',
  ADD_TITLE: 'Thêm Thẻ thành viên',
  EDIT_TITLE: 'Chỉnh sửa Thẻ thành viên',
  SEARCH: 'Tìm kiếm Thẻ thành viên',
  NO_RESULT_FOUND: 'Không tìm thấy Thẻ thành viên',
};

export default locale;
