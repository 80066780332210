const locale = {
  ACTION_CANCEL: 'Cancel',
  ACTION_SAVE: 'Save',
  ACTION_DELETE: 'Delete',
  ACTION_ADD: 'Add',
  ACTION_EDIT: 'Edit',
  ACTION_VIEW: 'View',
};

export default locale;
