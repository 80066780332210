import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import settingsConfig from 'app/configs/settingsConfig';
import axios from 'axios';
import { showMessage } from './enk/messageSlice';

export const getCartList = createAsyncThunk('cartModule/getCartList', async (options) => {
  const response = await axios.get(
    `${settingsConfig.apiURL}/salon/${options.salon_id}/cart/details`
  );
  if (!response.data.success || !response.data.items) {
    throw new Error('Failed to get cart list');
  }
  return {
    items: response.data.items.map((item) => ({ ...item, id: item.product_id })),
    totalItems: response.data.totalItems || response.data.items.length,
  };
});

export const getCartDetails = createAsyncThunk('cartModule/getCartDetails', async (options) => {
  const response = await axios.get(
    `${settingsConfig.apiURL}/salon/${options.salon_id}/cart/${options.id}/details`
  );
  if (!response.data.success || !response.data.item) {
    throw new Error('Failed to get cart details');
  }
  return response.data.item;
});

export const removeProductFromCart = createAsyncThunk(
  'cartModule/removeProductFromCart',
  async (options) => {
    const response = await axios.delete(
      `${settingsConfig.apiURL}/salon/${options.salon_id}/cart/${options.product_id}/delete`
    );
    if (!response.data.success) {
      throw new Error('Failed to remove product from cart');
    }
    return options.product_id;
  }
);

export const checkoutCart = createAsyncThunk('cartModule/checkoutCart', async (options) => {
  const response = await axios.get(
    `${settingsConfig.apiURL}/salon/${options.salon_id}/cart/checkout`
  );
  if (!response.data.success) {
    throw new Error('Failed to checkout cart');
  }
  return options.id;
});

export const addProductInCart = createAsyncThunk(
  'cartModule/addProductInCart',
  async (options, { getState, dispatch }) => {
    const response = await axios.post(
      `${settingsConfig.apiURL}/salon/${options.salon_id}/cart/${options.product_id}/update`,
      options.data
    );
    if (!response.data.success || !response.data.item) {
      throw new Error('Failed to add product in cart');
    }
    if (!getState().cartModule.ids.includes(options.product_id)) {
      dispatch(setIsFetchingCart(true));
      dispatch(getCartList(options)).then(({ error }) => {
        dispatch(setIsFetchingCart(false));
        if (error) {
          dispatch(
            showMessage({
              message: error.message || 'Failed to get cart list',
              variant: 'error',
            })
          );
        }
      });
    }
    return { ...response.data.item, id: response.data.item.global_product_id };
  }
);

export const updateCartProducts = createAsyncThunk(
  'cartModule/updateCartProducts',
  async (options) => {
    const response = await axios.post(
      `${settingsConfig.apiURL}/salon/${options.salon_id}/cart/${options.id}/update/products`,
      options.data
    );
    if (!response.data.success || !response.data.item) {
      throw new Error('Failed to update cart');
    }
    return response.data.item;
  }
);

export const updateCartStatus = createAsyncThunk('cartModule/updateCartStatus', async (options) => {
  const response = await axios.get(
    `${settingsConfig.apiURL}/salon/${options.salon_id}/cart/${options.id}/update/status`
  );
  if (!response.data.success || !response.data.item) {
    throw new Error('Failed to update cart');
  }
  return response.data.item;
});

export const addProductsToInventory = createAsyncThunk(
  'cartModule/addProductsToInventory',
  async (options) => {
    const response = await axios.get(
      `${settingsConfig.apiURL}/salon/${options.salon_id}/cart/${options.id}/update/inventory`
    );
    if (!response.data.success || !response.data.item) {
      throw new Error('Error adding products to inventory');
    }
    return response.data.item;
  }
);

const cartAdapter = createEntityAdapter({});

export const { selectAll: selectCart, selectById: selectCartById } = cartAdapter.getSelectors(
  (state) => state.cartModule
);

const cartSlice = createSlice({
  name: 'cartModule',
  initialState: cartAdapter.getInitialState({
    searchText: '',
    totalItems: 0,
    isFetchingCart: false,
  }),
  reducers: {
    setCartSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setIsFetchingCart: {
      reducer: (state, action) => {
        state.isFetchingCart = action.payload;
      },
      prepare: (value) => ({ payload: value }),
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCartList.fulfilled, (state, action) => {
      cartAdapter.setAll(state, action.payload.items);
      state.totalItems = action.payload.totalItems || action.payload.items.length;
    });
    builder.addCase(removeProductFromCart.fulfilled, (state, action) =>
      cartAdapter.removeOne(state, action.payload)
    );
    builder.addCase(addProductInCart.fulfilled, (state, action) => {
      cartAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(updateCartProducts.fulfilled, (state, action) =>
      cartAdapter.upsertOne(state, action.payload)
    );
    builder.addCase(checkoutCart.fulfilled, (state, action) => cartAdapter.removeAll(state));
  },
});

export const { setCartSearchText, setIsFetchingCart } = cartSlice.actions;

export const selectCartSearchText = ({ cartModule }) => cartModule.searchText;
export const selectTotalCart = ({ cartModule }) => cartModule.totalItems;
export const selectIsFetchingCart = ({ cartModule }) => cartModule.isFetchingCart;

export default cartSlice.reducer;
