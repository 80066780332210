const locale = {
  TITLE: 'Referral',
  UNDER_TITLE: 'View & Manage Referral data',
  ACTION_CANCEL: 'Cancel',
  ACTION_SAVE: 'Save',
  ACTION_DELETE: 'Delete',
  ACTION_ADD: 'Add',
  ACTION_EDIT: 'Edit',
  ADD_TITLE: 'Add Appointment',
  EDIT_TITLE: 'Edit Appointment',
  SEARCH: 'Search Appointment',
  NO_RESULT_FOUND: 'No data found',
};

export default locale;
