import SpaRegistrationPage from './SpaRegistrationPage';

const SpaRegistrationConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'spa-registration',
      element: <SpaRegistrationPage />,
    },
  ],
};

export default SpaRegistrationConfig;
