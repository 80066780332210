import AppContext from 'app/AppContext';
import { Component } from 'react';
import { matchRoutes } from 'react-router-dom';
import withRouter from '@enk/core/withRouter';
import history from '@history';
import {
  getSessionRedirectUrl,
  resetSessionRedirectUrl,
  setSessionRedirectUrl,
} from '@enk/core/EnkAuthorization/sessionRedirectUrl';
import settingsConfig from 'app/configs/settingsConfig';
import { getSessionBooking } from 'src/app/main/landing-pages/salon-details/bookingSession';

const ignoredPaths = [
  '/',
  '/spa-registration',
  '/callback',
  '/404',
  '/about-us',
  '/faq',
  '/privacy-policy',
  '/terms',
];
const authPaths = ['/sign-up', '/sign-in', '/sign-out', '/logout'];

class EnkAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
      routes,
    };
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, data: user } = props;
    const { pathname } = location;

    const matchedRoutes = matchRoutes(state.routes, pathname);

    const matched = matchedRoutes ? matchedRoutes[0] : false;

    if (getSessionBooking()?.payload?.salon_id) {
      setSessionRedirectUrl(`/${getSessionBooking()?.payload?.salon_id}`);
    }

    if (pathname === '/sign-out') {
      return { accessGranted: true };
    }
    if (authPaths.includes(pathname) && user?.id) {
      return { accessGranted: false };
    }
    if (ignoredPaths.includes(pathname)) {
      return { accessGranted: true };
    }
    if (pathname === '/profile' && !user?.id) {
      return { accessGranted: false };
    }

    if (pathname === '/reset-password' || pathname === '/reset-password/') {
      return { accessGranted: false };
    }

    if (/^\/reset-password\/(.*){1,1000}$/.test(pathname)) {
      return { accessGranted: true };
    }

    return { accessGranted: !!matched };
  }

  redirectRoute() {
    const {
      location: { pathname },
      data: user,
    } = this.props;

    // console.log({ pathname, user });

    const redirectUrl =
      getSessionRedirectUrl() || user.loginRedirectUrl || settingsConfig.loginRedirectUrl;

    if (!ignoredPaths.includes(pathname) && !user?.id && !authPaths.includes(pathname)) {
      setTimeout(() => history.push('/'), 0);
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or loginRedirectUrl
        */
      setTimeout(() => history.push(redirectUrl), 0);

      resetSessionRedirectUrl();
    }
  }

  render() {
    // console.info('Enk Authorization rendered', this.state.accessGranted);
    return this.state.accessGranted ? this.props.children : null;
  }
}

EnkAuthorization.contextType = AppContext;

export default withRouter(EnkAuthorization);
