import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEnkCurrentLayoutConfig, selectToolbarTheme } from 'app/store/enk/settingsSlice';
import Logo from 'app/theme-layouts/shared-components/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { selectUser } from 'app/store/userSlice';
import { Button, Dialog, DialogContent } from '@mui/material';
import UserMenu from '../../shared-components/UserMenu';
import SignInForm from '../../../main/sign-in/SignInForm';
import SignUpForm from '../../../main/sign-up/SignUpForm';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';

const AuthBasicType = {
  SignIn: 'SignIn',
  SignUp: 'SignUp',
};

function ToolbarLayout2(props) {
  const config = useSelector(selectEnkCurrentLayoutConfig);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const user = useSelector(selectUser);
  const [openAuthInDialog, setOpenAuthInDialog] = useState(false);
  const [authBasicType, setAuthBasicType] = useState(AuthBasicType.SignIn);
  const navigate = useNavigate();

  function handleCloseDialog() {
    setOpenAuthInDialog(false);
  }

  function onGuestSubmit(data) {
    navigate('/profile');
  }

  function onSignInLinkClick() {
    setAuthBasicType(AuthBasicType.SignIn);
    setOpenAuthInDialog(true);
  }

  function onSignUpLinkClick() {
    setAuthBasicType(AuthBasicType.SignUp);
    setOpenAuthInDialog(true);
  }

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="enk-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
      >
        <Toolbar className="container p-0 px-10 min-h-64 !max-w-[1200px]">
          <div className="flex shrink-0 items-center px-8">
            <Link to="/">
              <Logo mode="light" />
            </Link>
          </div>

          <div className="flex items-center pl-8 pr-16 h-full overflow-x-auto flex-1 justify-end">
            {/* <LanguageSwitcher /> */}
            {user?.data?.id ? (
              <UserMenu />
            ) : (
              <>
                <Button
                  onClick={onSignUpLinkClick}
                  variant="contained"
                  color="secondary"
                  className="rounded-4 mr-16 p-2 text-12 sm:text-16 sm:px-16 sm:py-6"
                >
                  Sign Up
                </Button>
                <Button
                  onClick={onSignInLinkClick}
                  variant="contained"
                  color="secondary"
                  className="rounded-4 p-2 text-12 sm:text-16 sm:px-16 sm:py-6"
                >
                  Sign In
                </Button>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Dialog open={openAuthInDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogContent>
          <div className="flex justify-center items-center pb-20">
            {authBasicType === AuthBasicType.SignIn && (
              <SignInForm
                className="!max-w-[550px] w-full"
                onSignUpLinkClickCallback={() => {
                  setAuthBasicType(AuthBasicType.SignUp);
                }}
                handleCloseAuthDialog={handleCloseDialog}
              />
            )}
            {authBasicType === AuthBasicType.SignUp && (
              <SignUpForm
                className="!max-w-[550px] w-full"
                onSignInLinkClickCallback={() => {
                  setAuthBasicType(AuthBasicType.SignIn);
                }}
                handleCloseAuthDialog={handleCloseDialog}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout2);
