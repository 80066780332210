export const lightPaletteText = {
  primary: 'rgb(17, 24, 39)',
  secondary: 'rgb(107, 114, 128)',
  disabled: 'rgb(149, 156, 169)',
};

export const darkPaletteText = {
  primary: '#ffffff',
  secondary: '#ffc7e2',
  disabled: '#1a0829',
};

const themesConfig = {
  default: {
    palette: {
      mode: 'light',
      divider: '#e2e8f0',
      text: lightPaletteText,
      common: {
        black: 'rgb(17, 24, 39)',
        white: 'rgb(255, 255, 255)',
      },
      primary: {
        light: '#9b6cc0',
        main: '#6b309b',
        dark: '#391b51',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        light: '#fd74b6',
        main: '#ed348d',
        dark: '#b50f5f',
        contrastText: darkPaletteText.primary,
      },
      background: {
        paper: '#FFFFFF',
        default: '#f1f5f9',
        tableHeader: '#e7e7e7',
      },
      error: {
        light: '#ffcdd2',
        main: '#d71d24',
        dark: '#89090e',
      },
      edit: {
        light: '#1d5eff',
        main: '#0060d3',
        dark: '#1d5eff',
      },
    },
    status: {
      danger: 'red',
    },
  },
  defaultDark: {
    palette: {
      mode: 'dark',
      divider: 'rgba(241,245,249,.12)',
      text: darkPaletteText,
      common: {
        black: 'rgb(1, 1, 1)',
        white: 'rgb(255, 255, 255)',
      },
      primary: {
        light: '#9b6cc0',
        main: '#6b309b',
        dark: '#391b51',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        light: '#fd74b6',
        main: '#ed348d',
        dark: '#b50f5f',
        contrastText: darkPaletteText.primary,
      },
      background: {
        paper: '#210d31',
        default: '#391b51',
        tableHeader: '#6b309b',
      },
      error: {
        light: '#ffcdd2',
        main: '#d71d24',
        dark: '#89090e',
      },
      edit: {
        light: '#0060d3',
        main: '#1d5eff',
        dark: '#0060d3',
      },
      status: {
        danger: 'red',
      },
    },
  },
};

export default themesConfig;
