import { lazy } from 'react';

const AboutUsPage = lazy(() => import('./AboutUsPage'));

const AboutUsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'about-us',
      element: <AboutUsPage />,
    },
  ],
};

export default AboutUsConfig;
