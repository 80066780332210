const locale = {
  TITLE: 'Giới thiệu',
  UNDER_TITLE: 'Xem và Quản lý dữ liệu Giới thiệu',
  ACTION_CANCEL: 'Hủy',
  ACTION_SAVE: 'Lưu',
  ACTION_DELETE: 'Xóa',
  ACTION_ADD: 'Thêm',
  ACTION_EDIT: 'Chỉnh sửa',
  ADD_TITLE: 'Thêm Lịch hẹn',
  EDIT_TITLE: 'Chỉnh sửa Lịch hẹn',
  SEARCH: 'Tìm kiếm Lịch hẹn',
  NO_RESULT_FOUND: 'Không tìm thấy dữ liệu',
};

export default locale;
