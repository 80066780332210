const storageKey = 'sessionBooking';

/**
 * Returns user's last online booking form data.
 * @returns {any} online booking form data.
 */
export const getSessionBooking = () => {
  try {
    if (window.sessionStorage.getItem(storageKey)) {
      return JSON.parse(window.sessionStorage.getItem(storageKey));
    }
    return null;
  } catch (e) {
    return null;
  }
};

/**
 * Sets last online booking form data.
 * @param {any} value
 */
export const setSessionBooking = (value) => {
  try {
    if (value) {
      window.sessionStorage.setItem(storageKey, JSON.stringify(value));
    }
  } catch (e) {
    /* empty */
  }
};

/**
 * Resets session by removing last online booking form data storage item.
 */
export const resetSessionBooking = () => {
  try {
    window.sessionStorage.removeItem(storageKey);
  } catch (e) {
    /* empty */
  }
};
