const locale = {
  TITLE: 'Hồ sơ',
  UNDER_TITLE: 'Xem và Quản lý dữ liệu Hồ sơ',
  ACTION_CANCEL: 'Hủy',
  ACTION_SAVE: 'Lưu',
  ACTION_DELETE: 'Xóa',
  ACTION_ADD: 'Thêm',
  ACTION_EDIT: 'Chỉnh sửa',
};

export default locale;
