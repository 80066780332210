import { ThemeProvider } from '@mui/material/styles';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/enk/settingsSlice';
import { Box, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Link } from 'react-router-dom';
import EnkSvgIcon from '@enk/core/EnkSvgIcon';

function FooterLayout2(props) {
  const footerTheme = useSelector(selectFooterTheme);

  return (
    <ThemeProvider theme={footerTheme}>
      <Box component="footer" className="py-[8rem] bg-[#323232] text-white">
        <Container>
          <Grid container rowSpacing={3} columnSpacing={6}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography className="text-20 font-bold mb-20">Company</Typography>
              <Link className="block mb-6 !text-white !no-underline" to="/about-us">
                About Us
              </Link>
              <Link className="block mb-6 !text-white !no-underline" to="/">
                Contact Us
              </Link>
              <Link className="block mb-6 !text-white !no-underline" to="/">
                Blog
              </Link>
              <Link className="block mb-6 !text-white !no-underline" to="/terms">
                Terms Of Use
              </Link>
              <Link className="block mb-6 !text-white !no-underline" to="privacy-policy">
                Privacy Policy
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography className="text-20 font-bold mb-20">For Business</Typography>
              <Link className="block mb-6 !text-white !no-underline" to="/faq">
                FAQs
              </Link>
              <Link className="block mb-6 !text-white !no-underline" to="/">
                Testimonials
              </Link>
              <Link className="block mb-6 !text-white !no-underline" to="/spa-registration">
                Sign Up
              </Link>
              <Link className="block mb-6 !text-white !no-underline" to="/">
                Spa Login
              </Link>
              <Link className="block mb-6 !text-white !no-underline" to="/">
                Staff Login
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography className="text-20 font-bold mb-20">Social Link</Typography>
              <div className="flex gap-10 flex-wrap">
                <Link className="inline-block !text-white" to="/">
                  <EnkSvgIcon>feather:facebook</EnkSvgIcon>
                </Link>
                <Link className="inline-block !text-white" to="/">
                  <EnkSvgIcon>feather:twitter</EnkSvgIcon>
                </Link>
                <Link className="inline-block !text-white" to="/">
                  <EnkSvgIcon>feather:linkedin</EnkSvgIcon>
                </Link>
                <Link className="inline-block !text-white" to="/">
                  <EnkSvgIcon>feather:youtube</EnkSvgIcon>
                </Link>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default memo(FooterLayout2);
